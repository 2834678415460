<template>
  <div class="page">
    <div class="top">
      <img src="../../static/home/banner.png" class="img" />
    </div>
    <div class="content">
      <div class="left">
        <div class="info">
          <div class="doctor-avatar">
            <img :src="$host + usersInfo.avatar" class="img" />
          </div>
          <div class="doctor-info">
            <div class="info-left">
              <div class="nickname">
                {{ usersInfo.nickname }}
                <div class="button" @click="onFocus">
                  {{ usersInfo.is_friend == "true" ? "取消关注" : "关注" }}
                </div>
              </div>
              <div class="dept">{{ usersInfo.position_name }}</div>
              <div class="bread">服务人次：{{ usersInfo.user_count }}  /  好评率： {{usersInfo.favorable_rate}}%  /  执业年限：{{usersInfo.workingtime}}</div>
              <div class="like">擅长： {{ usersInfo.collections.join('、') }}</div>
              <div class="desc">个人介绍：{{ usersInfo.description }}</div>
            </div>
          </div>
        </div>

        <div class="rili">
          <div class="order-table">
            <div class="order-table-thead">
              <b-icon class="order-icon" icon="chevron-left" @click="prview"></b-icon>
              <span style="margin: 0 20px">{{ check.year }} 年 {{ check.month }} 月</span>
              <b-icon class="order-icon" icon="chevron-right" @click="next"></b-icon>
            </div>
            <div class="order-table-tbody">
            <b-overlay :show="loading">
              <tr style="display: flex; margin: 20px 0">
                <div class="order-table-th" v-for="(item, index) in weeks" :key="index">
                  {{ item }}
                </div>
              </tr>
              <tr style="display: flex; margin: 20px 0; flex-wrap: wrap">
                <div
                  style="width: 104px; height: 64px; text-align: center"
                  v-for="(item, index) in days"
                  :key="index"
                >
                  <div style="height: 36px; line-height: 36px">
                    <div
                      :class="
                        today.join('') == check.year + '' + check.month + '' + item.text
                          ? 'active-day'
                          : ''
                      "
                    >
                      {{
                        today.join("") == check.year + "" + check.month + "" + item.text
                          ? "今"
                          : item.text
                      }}
                    </div>
                  </div>
                  <div v-if="item.text" style="font-size: 12px">
                    <span style="color: #e6e7e9" v-if="item.has">有预约</span>
                    <span style="color: #fb776a" v-else>可预约</span>
                  </div>
                </div>
              </tr>
            </b-overlay>
            </div>
          </div>
        </div>

        <div class="tools">
          <div class="tool">
            <div class="icon-area">
              <img class="tool-icon" src="../../static/doctor/pice.png" />
            </div>
            <div
              style="width: 160px; text-align: left; font-size: 12px; padding-right: 5px"
            >
              <div class="tool-title" style="font-size: 14px">图文咨询 <span style="margin-left: 20px">￥{{usersInfo.graphic_amount}}</span></div>
              <div style="height: 36px">可以和医生多次图文沟通</div>
              <div class="tool-tag">去问诊</div>
            </div>
          </div>
          <div class="tool">
            <div class="icon-area">
              <img class="tool-icon" src="../../static/doctor/phone.png" />
            </div>
            <div
              style="width: 160px; text-align: left; font-size: 12px; padding-right: 5px"
            >
              <div class="tool-title" style="font-size: 14px">语音咨询 <span style="margin-left: 20px">￥{{usersInfo.voice_amount}}</span></div>
              <div style="height: 36px">快捷方便，语音连线解决困惑</div>
              <div class="tool-tag">去问诊</div>
            </div>
          </div>
          <div class="tool">
            <div class="icon-area">
              <img class="tool-icon" src="../../static/doctor/video.png" />
            </div>
            <div
              style="width: 160px; text-align: left; font-size: 12px; padding-right: 5px"
            >
              <div class="tool-title" style="font-size: 14px">视频咨询 <span style="margin-left: 20px">￥{{usersInfo.video_amount}}</span></div>
              <div style="height: 36px">像聊天一样轻松，48小时内随便问</div>
              <div class="tool-tag">去问诊</div>
            </div>
          </div>
        </div>

        <div class="tabs">
          <div
            class="tab"
            :class="current == 0 ? 'tab-active' : ''"
            @click="changePage(0)"
          >
            服务项目
          </div>
          <div
            class="tab"
            :class="current == 1 ? 'tab-active' : ''"
            @click="changePage(1)"
          >
            服务案例
          </div>
          <div
            class="tab"
            :class="current == 2 ? 'tab-active' : ''"
            @click="changePage(2)"
          >
            社区文章
          </div>
          <div
            class="tab"
            :class="current == 3 ? 'tab-active' : ''"
            @click="changePage(3)"
          >
            资质
          </div>
        </div>

        <div class="current" v-show="current == 0">
          <doctor-goods :id="id" ref="goods"></doctor-goods>
        </div>
        <div class="current" v-show="current == 1">
          <doctor-serve :id="id" ref="serve"> </doctor-serve>
        </div>
        <div class="current" v-show="current == 2">
          <doctor-article :id="id" ref="article"></doctor-article>
        </div>
        <div class="current" v-show="current == 3">
          <doctor-paper :info="usersInfo.working"></doctor-paper>
        </div>
      </div>
      <div class="right">
        <div class="online">在线立即咨询</div>
        <div class="online-border">
          <div class="online-border-top">
            <div class="online-phone">
              <b-icon icon="telephone-fill"></b-icon>
            </div>
            <div style="width: 250px; margin-left: 10px">
              <div>致电医生助理</div>
              <div>400-851-5180</div>
            </div>
          </div>
          <div class="online-border-bottom">
            <div class="bottom-item">
              <b-icon style="color: #fda486; margin-right: 5px" icon="heart"></b-icon>
              一对一服务
            </div>
            <div class="bottom-item">
              <b-icon style="color: #fda486; margin-right: 5px" icon="heart"></b-icon>
              快速约见医生
            </div>
            <div class="bottom-item">
              <b-icon style="color: #fda486; margin-right: 5px" icon="heart"></b-icon>
              个人定制服务
            </div>
            <div class="bottom-item">
              <b-icon style="color: #fda486; margin-right: 5px" icon="heart"></b-icon>
              美蜂安全保障
            </div>
          </div>
        </div>
        <div class="list">
          <div class="list-title">美蜂优选</div>
          <div class="li" v-for="(item, index) in hots" :key="index">
            <img
              :src="$host + item.image_default_id"
              style="width: 90px; height: 90px; margin-right: 10px"
            />
            <div style="width: 230px">
              <div class="li-title">{{ item.name }}</div>
              <div class="li-desc" style="text-align: right; color: #fb776a">
                <span style="float: left; color: #999">推荐</span>               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DoctorGoods from "./doctorGoods.vue";
import DoctorServe from "./doctorServe.vue";
import DoctorArticle from "./doctorArticle.vue";
import DoctorPaper from "./doctorPapers.vue";
export default {
  data() {
    return {
      days: [],
      weeks: ["日", "一", "二", "三", "四", "五", "六"],
      now: {
        year: "",
        month: "",
        day: "",
      },
      today: [],
      check: {
        year: "2021",
        month: "12",
        day: "24",
      },
      recomment: [1, 2, 3, 4, 5, 6],
      current: 0,
      usersInfo: {},
      id: "",
      hots: [],
      loading: false
    };
  },
  components: {
    DoctorGoods,
    DoctorServe,
    DoctorArticle,
    DoctorPaper,
  },
  mounted() {
    this.id = this.$route.query.id;
    
    let date = new Date();
    this.now.year = date.getFullYear();
    this.now.month = date.getMonth() + 1;
    this.now.day = date.getDate();
    this.today = [this.now.year, this.now.month, this.now.day];
    let time = this.now.year + "/" + this.now.month + "/01 00:00:01";
    this.check = JSON.parse(JSON.stringify(this.now));
    this.getDay(time);
    this.getInfo();
    this.getHot();
  },
  methods: {
    getDay(newTime) {
      let time = newTime ? newTime : "";
      let date = new Date(time);
      this.check.year = date.getFullYear();
      this.check.month = date.getMonth() + 1;
      date.setMonth(this.check.month);
      date.setDate(0);
      this.weekday = new Date(time).getDay();
      let dates = date.getDate();
      this.renderList(dates);
    },
    //渲染日历
    renderList(dates) {
      this.days = [];
      let weekday = Number(this.weekday);
      for (let i = 1; i <= dates; i++) {
        this.days.push({
          text: i,
          has: false,
        });
      }
      for (let i = 0; i < weekday; i++) {
        this.days.unshift(" ");
      }
    },
    //切换tab
    changePage(index) {
      if (this.current == index) return;
      switch(index) {
        case 0:
        this.$refs.goods.getGoods();
        break;
        case 1:
        this.$refs.serve.getServes(); 
        break;
        case 2:
        this.$refs.article.getArticle();
        break;  
      }
      this.current = index;
    },
    getInfo() {
      // console.log(this.$route.query.id)
      this.$http
        .doctorinfo({
          id: this.$route.query.id,
        })
        .then((res) => {
          let { data, code, msg } = res;
          if (code == 1) {
            this.usersInfo = data;
          } else {
            this.$store.commit("showToast", msg);
          }
        });
    },
    getHot() {
      this.$http.hotlist({}).then((res) => {
        let { data, code } = res;
        if (code == 1) {
          this.hots = data.items;
        }
      });
    },
    onFocus() {
      console.log(this.usersInfo);
      let data = {
        id: this.$route.query.id,
        ident: "doctors",
      };
      this.$http.setattention(data).then((res) => {
        if (res.code == 1) {
          this.$store.commit("showToast", res.msg);
          setTimeout(() => {
            this.getInfo();
          }, 2000);
        } else {
          this.$store.commit("showToast", res.msg);
        }
      });
    },
    //月份后退
    prview() {
      
      this.active = "";
      this.datas = [];
      if (this.check.year <= this.now.year && this.check.month <= this.now.month) {
        return;
      }
      this.loading = true
      this.check.month -= 1;
      if (this.check.month < 1) {
        this.check.month = 12;
        this.check.year -= 1;
      }
      let month = this.check.month > 9 ? this.check.month : "0" + this.check.month;
      let newTime = this.check.year + "/" + month + "/01 00:00:01";
      this.getDay(newTime);
      this.loading = false
    },
    next() {
      this.loading = true
      this.active = "";
      this.datas = [];
      this.check.month += 1;
      if (this.check.month > 12) {
        this.check.month = 1;
        this.check.year += 1;
      }
      let month = this.check.month > 9 ? this.check.month : "0" + this.check.month;
      let newTime = this.check.year + "/" + month + "/01 00:00:01";
      this.getDay(newTime);
      this.loading = false
    },
  },
};
</script>
<style scoped lang="scss">
@import "./info.scss";
</style>
