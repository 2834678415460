<template>
  <div class="template">
    <div
      class="goods"
      v-for="(item, index) in goodsList"
      :key="index"
      @click="openURL(item.id)"
    >
      <img class="img" :src="item.src" @error="defaultIMG(index)" />
      <div class="goods-name">{{ item.title }}</div>
      <div class="time">{{ $timeFormat(item.createtime) }}</div>
    </div>
    <div v-if="goodsList.length == 0" style="height: 300px">暂无数据</div>
    <div class="page-toolbar">
      <b-pagination v-model="page" :total-rows="count" :per-page="limit"></b-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      goodsList: [],
      limit: 6,
      page: 1,
      count: 0,
    };
  },
  props: {
    id: String,
  },
  watch: {
    page() {
      this.getServes();
    },
  },
  methods: {
    getServes() {
      this.$http
        .casestudylist({
          doctors_id: this.id,
          page: this.page,
          limit: this.limit,
        })
        .then((res) => {
          let { data, code, msg } = res;
          if (code == 1) {
            data.items.forEach((item) => {
              item.src = this.$host + item.images.split(",")[0];
            });
            this.goodsList = data.items;
          } else {
            this.$store.commit("showToast", msg);
          }
        });
    },
    defaultIMG(index) {
      this.goodsList[index].src = require("../../static/my/empty-image-error.png");
    },
    openURL(id) {
      let url = "/doctor_serve?id=" + id + '&doctor=' + this.$route.query.id
      this.$router.push({
        path: url,
      })
    },
  },
};
</script>
<style scoped lang="scss">
.template {
  width: 798px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.goods {
  width: 266px;
  padding: 15px 13px;
  margin-bottom: 20px;
  border: 1px solid #e6e7e9;
  border-radius: 4px;
  .img {
    width: 240px;
    height: 240px;
    object-fit: contain;
  }
  .title {
    color: #ff7690;
    font-size: 23px;
  }
  .goods-name {
    color: #333;
    margin: 5px 0;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    overflow: hidden;
  }
  .time {
    font-size: 14px;
    color: #999;
  }
}
.goods:hover {
  cursor: pointer;
}
.page-toolbar {
  width: 798px;
  margin: 20px 0;
}
</style>
