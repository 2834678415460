<template>
  <div class="template">
    <div class="goods">
      <img class="img" :src="$host + info.d_image" v-show="info.d_image != ''" />
    </div>
    <!-- <div class="goods">
      <img class="img" :src="$host + info.ident_n" />
    </div> -->
    <!-- <div class="goods">
      <img class="img" :src="$host + info.ident_p" />
    </div> -->
    <div class="goods">
      <img class="img" :src="$host + info.p_d_image" v-show="info.p_d_image != ''" />
    </div>
    <div class="goods">
      <img class="img" :src="$host + info.p_h_image" v-show="info.p_h_image != ''" />
    </div>
    <div class="goods">
      <img class="img" :src="$host + info.p_image" v-show="info.p_image != ''" />
    </div>
    <div class="goods">
      <img class="img" :src="$host + info.w_d_image" v-show="info.w_d_image != ''" />
    </div>
    <div class="goods">
      <img class="img" :src="$host + info.w_p_image" v-show="info.w_p_image != ''" />
    </div>
    <div class="goods">
      <img class="img" :src="$host + info.w_u_image" v-show="info.w_u_image != ''" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    info: Object,
  },
  methods: {
    defaultImg(event) {
      event.src = require("../../static/doctor/fm1.png");
    },
  },
};
</script>
<style scoped lang="scss">
.template {
  width: 798px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.goods {
  width: 266px;
  padding: 15px 13px;
  margin-bottom: 20px;
  //   border: 1px solid #e6e7e9;
  border-radius: 4px;
  .img {
    width: 240px;
    height: 160px;
  }
}
</style>
